<template>
  <div class="container">
    <BaseHeader :title="$t('views.affiliation.text_923')"> </BaseHeader>

    <b-tabs
      class="mt-5"
      content-class="mt-3"
      nav-class="TabHeader"
      :active-nav-item-class="isHeaven ? 'TabHeader-heaven--active' : 'TabHeader--active'"
      :no-nav-style="true"
      @activate-tab="tabSelect"
    >
      <!-- My Affiliateds -->
      <b-tab :title="$t('views.affiliation.text_924')" active>
        <MeusAfiliados ref="myaffiliates" />
      </b-tab>
      <!-- My Affiliations -->
      <b-tab :title="$t('views.affiliation.text_1507')">
        <!-- BODY -->
        <b-row class="Grid-body">
          <div class="container">
            <h5 class="my-3">
              {{ affiliateProducts.length }} {{ $t('views.affiliation.text_1508') }}
            </h5>
            <!-- Loading -->
            <div
              v-if="loading"
              class="py-5 my-5 d-flex justify-content-center align-items-center"
            >
              <b-spinner variant="dark" label="Spinning"></b-spinner>
            </div>

            <b-row v-if="!loading && affiliateProducts.length > 0" class="container">
              <b-col
                cols="12"
                md="4"
                class="my-4 px-2 produto-item"
                v-for="item in affiliateProducts"
                :key="item.id"
              >
                <ProductPreview :isAffiliate="true">
                  <template v-slot:button="{ openPanel }">
                    <div
                      class="Grid-body-thumb"
                      @click="
                        openPanel(
                          item.product.id,
                          getLink(item.product.links),
                          true
                        )
                      "
                    >
                      <b-img
                        v-if="item.product.images.length > 0"
                        :src="item.product.images[0].path"
                        fluid
                      ></b-img>
                    </div>
                  </template>
                </ProductPreview>
                <b-dropdown
                  size="sm"
                  right
                  dropup
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="float-right drop-personalizado"
                >
                  <template v-slot:button-content>
                    <img v-if="!isHeaven" src="@/assets/img/icons/more.svg" class="btn-more" />
                    <img v-else src="@/assets/img/icons-heaven/more.svg" class="btn-more heaven" />
                  </template>
                  <b-dropdown-item @click="openPixels(item.product.id)"
                    >Pixels</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="copyLink(item, 'vendas')"
                    >{{ $t('views.affiliation.text_1509') }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="copyLink(item, 'checkout')"
                    >{{ $t('views.affiliation.text_1510') }}</b-dropdown-item
                  >
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="openLinks(item)"
                    >{{ $t('views.affiliation.text_1511') }}</b-dropdown-item
                  >
                </b-dropdown>
                <ProductPreview :isAffiliate="true">
                  <template v-slot:button="{ openPanel }">
                    <div
                      class="Grid-body-title"
                      @click="
                        openPanel(
                          item.product.id,
                          getLink(item.product.links),
                          true
                        )
                      "
                    >
                      {{ item.product.name }}
                    </div>
                  </template>
                </ProductPreview>
                <div class="Grid-body-money" :class="{'heaven': isHeaven}">
                  {{ isHeaven ? '$' : 'R$' }} {{ formatMoney(item.product.offers[0].amount) }}
                </div>
              </b-col>
            </b-row>

            <b-row
              v-if="!loading && affiliateProducts.length == 0"
              class="justify-content-center"
            >
              <p class="nao-encontrado">{{ $t('views.affiliation.text_1512') }}</p>
            </b-row>
          </div>
        </b-row>
        <!-- END BODY -->

        <Paginate
          v-if="affiliateProducts.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-tab>
      <b-tab :title="$t('views.affiliation.text_1513')">
        <b-container fluid>
          <b-row class="header-table">
            <div class="d-flex">
              <!-- Filter -->
              <div class="d-flex align-items-center">
                <b-tooltip
                  target="filtro-1"
                  :title="$t('views.affiliation.text_1514')"
                  placement="topright"
                />
                <b-button
                  id="filtro-1"
                  class="btn-table"
                  @click="filterToMe"
                  :title="$t('views.affiliation.text_1515')"
                >
                  <img v-if="!isHeaven" src="@/assets/img/icons/filtro.svg" />
                  <img v-else src="@/assets/img/icons-heaven/filtro.svg" />
                </b-button>
              </div>
              <!-- Date Range Filter -->
              <div class="datas">
                <date-range-picker
                  ref="picker"
                  opens="right"
                  :locale-data="localeDate"
                  :showWeekNumbers="false"
                  :showDropdowns="true"
                  :autoApply="false"
                  :ranges="dateRanges"
                  v-model="dateRange"
                  :class="{'heaven': isHeaven}"
                  @update="fetchToMe"
                >
                  <template
                    v-if="dateRange.startDate && dateRange.endDate"
                    v-slot:input="picker"
                    style="min-width: 350px"
                  >
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                  </template>
                  <template
                    v-else
                    v-slot:input
                    class="btn-table"
                    :title="$t('views.affiliation.text_1516')"
                  >
                    {{ $t('views.affiliation.text_1517') }}
                  </template>
                </date-range-picker>
              </div>
            </div>

            <b-col cols="12" md="4" class="container-pesquisa mt-2">
              <div class="inputSearch">
                <img src="@/assets/img/icons/search.svg" class="Pointer search tome-search" />
                <input
                  type="text"
                  :placeholder="$t('views.affiliation.text_1518')"
                  v-on:input="debounce"
                  v-model="search"
                  class="input-busca"
                />
              </div>
            </b-col>
            <b-col cols="2">
              <b-dropdown
                size="md"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                v-if="item_check.length > 1"
              >
                <template #button-content>
                  <a href="#" class="action-tome" data-anima="top">
                    <img src="@/assets/img/icons/zap.svg" />
                    {{ $t('views.affiliation.text_925') }}</a
                  >
                </template>
                <b-dropdown-item href="#" @click="accept(null, true)"
                  >{{ $t('views.affiliation.text_926') }}</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="remove(null, true)"
                  >{{ $t('views.affiliation.text_927') }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
          <a
            href="#"
            class="select-all"
            v-if="item_check2.length && filters_tome && total_toMe"
            data-anima="top"
            @click.prevent="selectAllToMe"
            >{{ perFilterToMe ? $t('views.affiliation.text_1519') : $t('views.affiliation.text_1520') }}
            {{ $t('views.affiliation.text_928') }} [{{ total_toMe }}]</a
          >
          <!-- HEADER -->
          <b-row class="Table-header mt-1">
            <b-col cols="0" class="d-none d-md-block">
              <b-form-checkbox-group
                id="checkbox-group-all"
                class="input-check"
                name="flavour-all"
                v-model="item_check2"
                @change="clickCheckAll"
              >
                <b-form-checkbox value="xx"></b-form-checkbox>
              </b-form-checkbox-group>
            </b-col>
            <b-col cols="4" md="2" class=""> {{ $t('views.affiliation.text_1521') }} </b-col>

            <b-col cols="5" md="3" class=""> {{ $t('views.affiliation.text_1522') }} </b-col>

            <b-col cols="2" class="d-none d-md-block"> {{ $t('views.affiliation.text_1523') }} </b-col>

            <b-col cols="3" class="d-none d-md-block"> {{ $t('views.affiliation.text_1524') }} </b-col>

            <b-col cols="3" md="1"> {{ $t('views.affiliation.text_1525') }} </b-col>
          </b-row>
          <!-- END HEADER -->

          <!-- BODY -->

          <template v-if="solicitationsToMe.length > 0 && !loading">
            <b-row
              class="Table-body"
              v-for="item in solicitationsToMe"
              :key="item.id"
            >
              <b-col cols="0" class="d-none d-md-block mr-3">
                <b-form-checkbox
                  :value="item"
                  :id="`checkbox-group-` + item.id"
                  :name="`flavour-` + item.id"
                  @change="clickCheck(item)"
                  v-model="item_check"
                ></b-form-checkbox>
              </b-col>
              <b-col
                cols="4"
                md="2"
                class="status-item ml-2"
                :class="getStatus(item.status).class"
                v-b-tooltip="{
                  title: getStatus(item.status).text,
                  placement: 'bottom',
                }"
              >
                <div class="Table-body-title">
                  {{
                    item.product.name.length > 20
                      ? item.product.name.substr(0, 20) + "..."
                      : item.product.name
                  }}
                </div>
                <div class="Table-body-subtitle">
                  {{
                    item.product.description.length > 20
                      ? item.product.description.substr(0, 20) + "..."
                      : item.product.description
                  }}
                </div>
              </b-col>
              <b-col
                cols="5"
                md="3"
                class="dados-cliente"
                v-if="item.affiliate"
              >
                <div>
                  {{ item.affiliate.name }}
                </div>
                <div>
                  {{ item.affiliate.email }}
                </div>
              </b-col>
              <b-col
                cols="3"
                md="3"
                class="dados-cliente"
                v-if="!item.affiliate"
              >
              </b-col>

              <b-col cols="2" class="d-none d-md-block">
                {{ item.created_at | datetime }}
              </b-col>

              <b-col cols="3" class="d-none d-md-block" v-if="item.observation">
                {{
                  item.observation.length > 20
                    ? item.observation.substr(0, 20) + "..."
                    : item.observation
                }}
              </b-col>
              <b-col cols="3" class="d-none d-md-block" v-else> ---</b-col>
              <b-col cols="2" md="1">
                <b-dropdown
                  size="sm"
                  right
                  dropup
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="drop-personalizado"
                >
                  <template v-slot:button-content>
                    <img src="@/assets/img/icons-heaven/more2.svg" class="btn-more btn-more2 heaven" v-if="isHeaven" />
                    <img src="@/assets/img/icons/more2.svg" class="btn-more btn-more2" v-else />
                  </template>

                  <b-dropdown-item class="drop-item" :class="{'heaven': isHeaven}" @click="accept(item)">
                    {{ $t('views.affiliation.text_929') }}
                  </b-dropdown-item>

                  <b-dropdown-item class="drop-item" :class="{'heaven': isHeaven}" @click="remove(item)">
                    {{ $t('views.affiliation.text_930') }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </template>
          <!-- Loading -->
          <div
            v-if="loading"
            class="py-5 my-5 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>
          <!-- Feedback Message -->
          <b-row
            v-if="!loading && solicitationsToMe.length == 0"
            class="Table-body justify-content-center"
          >
            <p class="nao-encontrado">{{ $t('views.affiliation.text_931') }}</p>
          </b-row>

          <!-- END BODY -->
        </b-container>

        <Paginate
          v-if="solicitationsToMe.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-tab>
      <b-tab :title="$t('views.affiliation.text_1526')">
        <b-container fluid>
          <!-- HEADER -->
          <b-row class="Table-header mt-1">
            <b-col cols="5" md="3" class=""> {{ $t('views.affiliation.text_1521') }} </b-col>

            <b-col cols="4" md="2" class=""> {{ $t('views.affiliation.text_1522') }} </b-col>

            <b-col cols="2" class="d-none d-md-block"> {{ $t('views.affiliation.text_1523') }} </b-col>

            <b-col cols="3" class="d-none d-md-block"> {{ $t('views.affiliation.text_1524') }} </b-col>

            <b-col cols="3" md="2"> {{ $t('views.affiliation.text_1527') }} </b-col>
          </b-row>
          <!-- END HEADER -->
          <!-- BODY -->
          <template v-if="solicitationsMine.length > 0 && !loading">
            <b-row
              class="Table-body"
              v-for="item in solicitationsMine"
              :key="item.id"
            >
              <b-col cols="5" md="3" class="">
                <div class="Table-body-title">
                  {{
                    item.product.name.length > 20
                      ? item.product.name.substr(0, 20) + "..."
                      : item.product.name
                  }}
                </div>
                <div class="Table-body-subtitle">
                  {{
                    item.product.description.length > 20
                      ? item.product.description.substr(0, 20) + "..."
                      : item.product.description
                  }}
                </div>
              </b-col>

              <b-col cols="4" md="2" class="">
                {{ item.affiliate ? item.affiliate.name : "" }}
              </b-col>

              <b-col cols="2" class="d-none d-md-block" v-if="item.updated_at">
                {{ item.updated_at | datetime }}
              </b-col>
              <b-col cols="2" class="d-none d-md-block" v-if="!item.updated_at">
                --
              </b-col>

              <b-col cols="3" class="d-none d-md-block" v-if="item.observation">
                {{
                  item.observation.length > 20
                    ? item.observation.substr(0, 20) + "..."
                    : item.observation
                }}
              </b-col>
              <b-col cols="3" md="2" class="d-none d-md-block" v-else> </b-col>

              <b-col cols="3" md="2">
                <span class="icon-blue" v-if="item.status == 'REQUESTED'"
                  >{{ $t('views.affiliation.text_1528') }}</span
                >
                <span class="icon-green" v-if="item.status == 'APPROVED'"
                  >{{ $t('views.affiliation.text_1529') }}</span
                >
                <span class="icon-red" v-if="item.status == 'REJECTED'"
                  >{{ $t('views.affiliation.text_1530') }}</span
                >
              </b-col>
            </b-row>
          </template>
          <!-- Loading -->
          <div
            v-if="solicitationsMine.length == 0 && loading"
            class="py-5 my-5 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>
          <!-- Feedback Message -->
          <b-row
            v-if="!loading && solicitationsMine.length == 0"
            class="table-body justify-content-center"
          >
            <p class="nao-encontrado">{{ $t('views.affiliation.text_931') }}</p>
          </b-row>

          <!-- END BODY -->
        </b-container>
      </b-tab>
      <!--
      <b-tab title="⚠️ Vitrine -> Remover da Prod" >
        <b-row class="Grid-body">
          <div class="container">
            <h5 class="my-3">
              {{ publicProducts.length }} Produtos encontrados
            </h5>
            <b-row
              v-if="loading"
              class="py-5 my-5 justify-content-center align-items-center"
            >
              <b-spinner variant="dark" label="Spinning"></b-spinner>
            </b-row>
            <b-row v-if="!loading && publicProducts.length > 0">
              <b-col
                cols="6"
                md="4"
                class="mb-3 produto-item"
                v-for="item in publicProducts"
                :key="item.id"
              >
                <ProductPreview>
                  <template v-slot:button="{ openPanel }">
                    <div
                      class="Grid-body-thumb"
                      @click="openPanel(item.slug, salesPage)"
                    >
                      <b-img
                        v-if="item.images.length > 0"
                        :src="item.images[0].path"
                        fluid
                      ></b-img>
                    </div>
                  </template>
                </ProductPreview>
                <b-dropdown
                  size="sm"
                  dropup
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="float-right drop-personalizado"
                >
                  <template v-slot:button-content>
                    <img src="@/assets/img/icons/more.svg" class="btn-more" />
                  </template>
                  <b-dropdown-item
                    href="javascript:void(0);"
                    @click="openModal(item)"
                    >Se Afiliar a este Produto</b-dropdown-item
                  >
                </b-dropdown>
                <ProductPreview>
                  <template v-slot:button="{ openPanel }">
                    <div
                      class="Grid-body-title"
                      @click="openPanel(item.slug, salesPage)"
                    >
                      {{ item.name }}
                    </div>
                  </template>
                </ProductPreview>
                <div class="Grid-body-description">
                  {{ item.description }}
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="!loading && publicProducts.length == 0"
              class="justify-content-center"
            >
              <p class="nao-encontrado">Nenhuma afiliação foi encontrado </p>
            </b-row>
          </div>
        </b-row>
        <Paginate
          v-if="publicProducts.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-tab> -->
    </b-tabs>
    <ModalPixels :product_id="pixelProductId"></ModalPixels>

    <CallEvaluation />

    <FiltersToMe @filter="filter" />
  </div>
</template>
<script>
import ProductService from "@/services/resources/ProductService";
import AffiliateService from "@/services/resources/AffiliateService";
import Paginate from "@/components/shared/Paginate";
import ProductPreview from "@/components/shared/ProductPreview";
import ModalPixels from "@/components/Seller/Affiliation/modals/pixels.vue";
import MeusAfiliados from "@/components/Seller/Affiliation/MeusAfiliados";
import Vue from "vue";
import DateRangePicker from "vue2-daterange-picker";
import FiltersToMe from "@/components/Seller/Affiliation/modals/filterToMe";

const serviceAffiliate = AffiliateService.build();
const serviceProduct = ProductService.build();

import moment from "moment-timezone";
import Money from "@/mixins/money";
import _ from "lodash";

export default {
  name: "MinhasAfiliações",
  components: {
    MeusAfiliados,
    FiltersToMe,
    ProductPreview,
    ModalPixels,
    Paginate,
    DateRangePicker,
  },
  mixins: [Money],
  filters: {
    moment(date) {
      return moment.tz(date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
  },
  data() {
    return {
      loading: true,
      loading_product: false,
      search: "",
      search_product: "",
      total_toMe: 0,
      perFilterToMe: false,
      products_list: [],
      product_items: [],
      publicProducts: [],
      affiliateProducts: [],
      tabIndex: 0,
      item_check: [],
      item_check2: [],
      product_id: -1,
      slug: "",
      sales_page: "",
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
      },
      solicitationsToMe: [],
      solicitationsMine: [],
      pixelProductId: "",
      filters_tome: {},
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: this.$t('locale_date.apply_label'),
        cancelLabel: this.$t('locale_date.cancel_label'),
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: this.$t('locale_date.days_of_week'),
        monthNames: this.$t('locale_date.month_names'),
        firstDay: 0,
      },
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
    }
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        [this.$t('locale_date.today')]: [today, today],
        [this.$t('locale_date.yesterday')]: [yesterday, yesterday],
        [this.$t('locale_date.last_week')]: [lastWeek, today],
        [this.$t('locale_date.current_month')]: [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        [this.$t('locale_date.current_year')]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t('locale_date.last_month')]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        [this.$t('locale_date.every_period')]: [
          new Date(2020,0,1),
          new Date(today),
        ],
      };
    },
    salesPage() {
      return this.isHeaven ? process.env.VUE_SALES_HEAVEN_PAGE : process.env.VUE_SALES_PAGE;
    },
    userId() {
      return this.$store.getters.getProfile.id;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    filter(filters) {
      this.filters_tome = filters;
      this.fetchToMe();
    },
    filterToMe() {
      this.$bvModal.show("tome-filters");
    },
    openLinks(item) {
      this.$router.push(
        `/links/?affiliation_id=${item.id}&p_name=${encodeURI(
          item.product.name
        )}&comingAffiliate=true`
      );
    },
    openPixels(id) {
      this.pixelProductId = id;
      this.$bvModal.show("affiliate-pixels");
    },
    selectAllToMe() {
      this.perFilterToMe = !this.perFilterToMe;

      let text = `${this.$t('views.affiliation.text_1533')} ${
        this.perFilterToMe ? this.$t('views.affiliation.text_1531') : this.$t('views.affiliation.text_1532')
      } ${this.total_toMe} ${this.$t('views.affiliation.text_1534')}`;

      this.$bvToast.toast(text, {
        title: this.$t('views.affiliation.text_1535'),
        variant: "info",
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status) {
          case "APPROVED": {
            r = { text: this.$t('views.affiliation.text_1536'), class: "is-green" };
            break;
          }
          case "DISABLED": {
            r = { text: this.$t('views.affiliation.text_1537'), class: "is-red" };
            break;
          }
          case "REJECTED": {
            r = { text: this.$t('views.affiliation.text_1538'), class: "is-red" };
            break;
          }
          case "REQUESTED": {
            r = { text: this.$t('views.affiliation.text_1539'), class: "is-warning" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    clickCheckAll(event) {
      if (!event.length) {
        this.item_check = [];
        this.item_check2 = [];
        return;
      }
      this.item_check = [];
      this.solicitationsToMe.forEach((item) => {
        this.item_check.push(item);
      });
    },
    clickCheck() {
      this.item_check2 = [];
    },
    //
    debounce: _.debounce(function () {
      this.total_toMe = 0;
      this.fetchToMe();
    }, 500),

    fetchToMe() {
      this.$router.replace({
        query: {
          start_date: this.dateRange.startDate,
          end_date: this.dateRange.endDate,
          ...this.filters
        },
      }).catch(() => {});

      this.loading = true;
      this.solicitationsToMe = [];
      this.item_check = [];
      this.item_check2 = [];

      let data = {
        type: "to-me",
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      // this.pagination.totalPages = 1;

      if (
        this.search != null &&
        typeof this.search == "string" &&
        this.search.length > 0
      ) {
        data.search = this.search;
      }

      if (this.dateRange.startDate != null && this.dateRange.endDate != null) {
        data.date_start = moment(this.dateRange.startDate, "MM-DD-YYYY")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT00:00:00-03:00");
        data.date_end = moment(this.dateRange.endDate, "MM-DD-YYYY")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DDT23:59:59-03:00");
      }

      Object.keys(this.filters_tome).forEach(
        (key) =>
          (this.filters_tome[key] == null ||
            this.filters_tome[key].length === 0) &&
          delete this.filters_tome[key]
      );
      data = Object.assign(data, this.filters_tome);

      serviceAffiliate
        .search(data)
        .then((response) => {
          this.solicitationsToMe = response.data;
          this.pagination.totalPages = response.last_page;

          this.total_toMe = response.total;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //
    fetchMine() {
      this.loading = true;

      let data = {
        type: "mine",
      };

      serviceAffiliate
        .search(data)
        .then((response) => {
          this.loading = false;
          this.solicitationsMine = response.data;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // aceitando afiliação
    accept(item, lote = false) {
      let text = "";
      if (lote) {
        if (this.perFilterToMe) {
          text = `${this.$t('views.affiliation.text_932')} [${this.total_toMe}] ${this.$t('views.affiliation.text_1540')}`;
        } else {
          text = `${this.$t('views.affiliation.text_932')} [${this.item_check.length}] ${this.$t('views.affiliation.text_1540')}`;
        }
      } else
        text = `${this.$t('views.affiliation.text_1541')} ${item.affiliate.name}`;

      Vue.swal({
        title: this.$t('views.affiliation.text_1542'),
        text: text,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t('views.affiliation.text_1543'),
        cancelButtonText: this.$t('views.affiliation.text_1544'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-info2 mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let data = {
            status: "APPROVED",
          };
          if (!lote) {
            data.ids = [item.id];
          } else {
            if (this.perFilterToMe) {
              // tudo do filtro
              data.search = this.search;
              data = {
                ...data,
                ...this.filters_tome,
              };
            } else {
              // apenas itens selecionados
              data.ids = this.item_check.map((item) => item.id);
            }
          }

          serviceAffiliate
            .update(data)
            .then(() => {
              if (lote) {
                this.$bvToast.toast(
                  this.$t('views.affiliation.text_933'),
                  {
                    title: this.$t('views.affiliation.text_1545'),
                    variant: "info",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              } else {
                this.$bvToast.toast(this.$t('views.affiliation.text_934'), {
                  title: this.$t('views.affiliation.text_1545'),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
              this.perFilterToMe = false;

              this.fetchToMe();
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    remove(item, lote = false) {
      let text = "";
      if (lote) {
        if (this.perFilterToMe) {
          text = `${this.$t('views.affiliation.text_935')} [${this.total_toMe}] ${this.$t('views.affiliation.text_1540')}`;
        } else {
          text = `${this.$t('views.affiliation.text_935')} [${this.item_check.length}] ${this.$t('views.affiliation.text_1540')}`;
        }
      } else
        text = `${this.$t('views.affiliation.text_936')} ${item.affiliate.name}`;

      Vue.swal({
        title: this.$t('views.affiliation.text_1546'),
        text: text,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: this.$t('views.affiliation.text_1547'),
        cancelButtonText: this.$t('views.affiliation.text_1544'),
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          let data = {
            status: "REJECTED",
          };

          if (!lote) {
            data.ids = [item.id];
          } else {
            if (this.perFilterToMe) {
              // tudo do filtro
              data.search = this.search;
              data = {
                ...data,
                ...this.filters_tome,
              };
            } else {
              // apenas itens selecionados
              data.ids = this.item_check.map((item) => item.id);
            }
          }

          serviceAffiliate
            .update(data)
            .then(() => {
              if (lote) {
                this.$bvToast.toast(
                  this.$t('views.affiliation.text_1548'),
                  {
                    title: this.$t('views.affiliation.text_1545'),
                    variant: "info",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              } else {
                this.$bvToast.toast(this.$t('views.affiliation.text_1549'), {
                  title: this.$t('views.affiliation.text_1545'),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
              this.perFilterToMe = false;
              this.fetchToMe();
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    getLink(links) {
      if (links.length) {
        return this.salesPage + "/redirect/" + links[0].id + "/" + this.userId;
      } else return "";
    },
    copyLink(item, type) {
      if (type == "vendas") {
        navigator.clipboard.writeText(
          `${this.salesPage}/redirect/${item.product.links[0].id}/?a_id=${this.userId}`
        );
      } else if (type == "checkout") {
        navigator.clipboard.writeText(
          `${this.salesPage}/${item.product_id}/?a_id=${this.userId}`
        );
      }

      this.$bvToast.toast(this.$t('views.affiliation.text_1550'), {
        variant: "success",
        title: this.$t('views.affiliation.text_1551'),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    tabSelect(newTabIndex) {
      this.tabIndex = newTabIndex;
      if (newTabIndex == 0) this.$refs.myaffiliates.fetchAll();
      if (newTabIndex == 1) this.fetchAffiliateProducts();
      // else if (newTabIndex == 1) this.fetchPublicProducts();
      if (newTabIndex == 2) this.fetchToMe();
      else if (newTabIndex == 3) this.fetchMine();
    },
    openModal(item) {
      this.product_id = item.id;
      this.$bvModal.show("affiliation-request");
    },
    toPage(page) {
      this.pagination.currentPage = page;
      if (this.tabIndex == 1) this.fetchAffiliateProducts();
      else if (this.tabIndex == 2) this.fetchToMe();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      if (this.tabIndex == 1) this.fetchAffiliateProducts();
      else if (this.tabIndex == 2) this.fetchToMe();
    },
    fetchAffiliateProducts() {
      this.loading = true;
      let data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      this.pagination.totalPages = 1;

      serviceAffiliate
        .search(data)
        .then((response) => {
          this.loading = false;
          this.affiliateProducts = response.data;
          this.pagination.totalPages = response.last_page;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    fetchPublicProducts() {
      this.publicProducts = [];
      let data = {
        type: "public",
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };
      this.pagination.totalPages = 1;

      serviceProduct
        .search(data)
        .then((response) => {
          this.loading = false;
          this.publicProducts = response.data;
          this.pagination.totalPages = response.last_page;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
  mounted() {
    this.dateRange.startDate = this.$route.query.start_date || moment();
    this.dateRange.endDate = this.$route.query.end_date || moment();
    if(this.$route.query && this.$route.query.status) this.filters.status = this.$route.query.status || "";
    if(this.$route.query && this.$route.query.products) this.filters.products = this.$route.query.products || "";
    this.fetchToMe();
  },
  created() {
    if (this.$store.getters.setPanel) {
      this.$router.push("/dashboard");
    }
  },
};
</script>

<style scoped lang="scss">
.produto-item {
  transition: 0.3s;
}
@media screen and (max-width: 768px) {
  h5 {
    font-size: 16px;
    margin-left: 20px;
  }
  .produto-item + .produto-item {
    margin-top: 10px;
  }
}
.btn-more {
  background: rgba(0, 228, 160, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px 15px;
  border-radius: 5px;
}
.btn-more.heaven {
  background: $base-color-heaven-light;
}

.Table-body {
  /* padding: 15px 20px !important;
   */
  padding: 15px;
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: rgba(0, 148, 136, 0.1);
}
.btn-table:first-child:active {
  background: rgba(0, 148, 136, 0.1);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(2) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  /* //justify-content: space-between; */
  align-items: center;
  padding: 30px 0;
  padding-top: 15px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 350px;
  height: 55px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 5px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 32px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .header-table {
    display: block;
    padding: 0;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
  }
  .search {
    left: 20px;
  }
  .tome-search {
    left: 30px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-more2 {
    padding: 10px !important;
  }
}
.btn-more2 {
  background: rgba(33, 51, 210, 0.1);
  outline: none;
}
.btn-more2.heaven {
  background: $base-color-heaven-light;
  outline: none;
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}
.select-all {
  font-size: 14px;
  color: #81858e;
  font-weight: 600;
  display: inline-block;
  margin-top: -10px;
}
.action-tome {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 10px;

  /* background: red; */
}
.action-tome img {
  max-width: 18px;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.action-tome:disabled,
.action-tome.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.status-item:before {
  left: -10px !important;
  top: 8px !important;
}
.datas {
  margin-left: 20px;
}
</style>
